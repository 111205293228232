import { useSnackbar } from 'notistack'
import { Fragment, useEffect } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom'

/**
 * 	initialValue: for testing
 *	client only: use appSnackbar
 *	server only: use edge.snackMsgs.push
 */
export let appSnackbar = {
	enqueueSnackbar: () => '',
	closeSnackbar: () => {},
} as ReturnType<typeof useSnackbar>

const Root = () => {
	appSnackbar = useSnackbar()

	const location = useLocation()
	const navType = useNavigationType()

	useEffect(() => {
		if (!location.hash && navType === 'PUSH' && location.key !== 'default') {
			window.scrollTo(0, 0)
		}
	}, [location])
	return <Fragment />
}

export default Root
