import { lazy as reactLazy, Suspense } from 'react'

export const lazy = (
	pageFn: () => Promise<any>,
	route: Record<string, any>,
	preloadAssets: () => Promise<any> = () => Promise.resolve(),
) => {
	let LazyComponent = reactLazy(pageFn)

	const info = {
		element: (
			<Suspense fallback={null}>
				<LazyComponent />
			</Suspense>
		),
		preload: () => Promise.all([pageFn(), preloadAssets()]),
		...route,
	}
	return info
}

export const lazyComponent = (compFn: () => Promise<any>) => {
	let LazyComponent = reactLazy(compFn)

	const Component = (props: any) => {
		return (
			<Suspense fallback={null}>
				<LazyComponent {...props} />
			</Suspense>
		)
	}

	return {
		Component,
		preload: compFn,
	}
}
