import { useCallback, useState } from 'react'

export const useBoolean = (initial: boolean = false) => {
	const [bool, setBool] = useState(initial)
	const setFalse = useCallback(() => {
		bool && setBool(false)
	}, [bool])
	const setTrue = useCallback(() => {
		!bool && setBool(true)
	}, [bool])
	const toggle = useCallback(() => {
		setBool(!bool)
	}, [bool])

	const action = {
		setFalse,
		setTrue,
		toggle,
		setBool,
	}
	return [bool, action] as [boolean, typeof action]
}

export const useAnchor = () => {
	const [anchorEl, openAnchorEl] = useState<Element | null>(null)
	const openAnchor = useCallback((e: React.MouseEvent<any, MouseEvent>) => {
		openAnchorEl(e.currentTarget)
	}, [])
	const clearAnchor = useCallback(() => {
		openAnchorEl(null)
	}, [])

	return {
		anchorEl,
		openAnchor,
		clearAnchor,
	}
}
