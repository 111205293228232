import { useCallback, useState } from 'react'
import { useDialog } from 'ui/common/hooks/dialog'

import Button, { ButtonTypeMap } from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

type Props = {
	title: string
	content: string
	confirmTxt?: string
	confirm: () => Promise<any>
	cancel?: () => void
	color?: ButtonTypeMap['props']['color']
}

export const useConfirmDialog = ({
	title,
	content,
	confirmTxt = 'ยืนยัน',
	cancel,
	confirm,
	color,
}: Props) => {
	const [loading, setLoading] = useState(false)
	const dialog = useDialog({ onClose: cancel })
	const { render, closeDialog, ...rest } = dialog
	const onConfirm = useCallback(() => {
		setLoading(true)
		confirm().finally(() => {
			closeDialog()
			setLoading(false)
		})
	}, [confirm, closeDialog])

	return {
		dialog: render(
			<>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{content}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						autoFocus
						color="inherit"
						disabled={loading}
						onClick={closeDialog}
					>
						ยกเลิก
					</Button>
					<Button
						variant="contained"
						disableElevation
						disabled={loading}
						onClick={onConfirm}
						color={color}
					>
						{confirmTxt}
					</Button>
					&nbsp;
				</DialogActions>
			</>,
		),
		closeDialog,
		...rest,
	}
}
