import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const Center = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}))

Center.defaultProps = {
	gap: 1,
	mx: 'auto',
	height: '100%',
}

export default Center
