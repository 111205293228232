import './startup'

import ReactDOM from 'react-dom/client'
import Application, { ApplicationProps } from 'ui/common/components/Application'

import SuspenseRouter from './modules/routes/components/SuspenseRouter'

export const render = ({ appProps }: { appProps: ApplicationProps }) => {
	const root = document.getElementById('root')!
	ReactDOM.createRoot(root).render(
		<SuspenseRouter>
			<Application {...appProps} />
		</SuspenseRouter>,
	)
}
