import merge from 'lodash.merge'
import {
	createContext,
	useCallback,
	useContext,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react'
import { injectCss } from 'ui/common/utils/script'
import { getCookie, setCookie } from 'ui/modules/auth/utils/cookie'

import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme'
import { lighten } from '@mui/system/colorManipulator'

export type PreferenceParam = {
	theme?: (isLightTheme: boolean) => Partial<ThemeOptions>
}

export const useProviderPreference = (p: PreferenceParam) => {
	const [themeType, setThemeType] = useState(
		getCookie(document.cookie).themeType || 'light',
	)
	const isLightTheme = themeType !== 'dark'

	const config = useMemo<ThemeOptions>(() => {
		const customRed = isLightTheme ? '#DB3131' : '#E95A4F'
		const t = p.theme?.(isLightTheme) ?? {}
		const mainBase = (t?.palette?.primary as string) ?? '#000000'
		const mainPalette = {
			main: isLightTheme ? mainBase : lighten(mainBase, 0.2),
		}

		return merge<ThemeOptions, ThemeOptions, ThemeOptions>(
			{
				palette: {
					mode: themeType,
					background: {
						default: isLightTheme ? '#F2F4F2' : '#060606',
					},
					error: {
						main: customRed,
					},
				},
				typography: {
					fontSize: 16,
				},
				components: {
					MuiFormControl: {
						defaultProps: {
							margin: 'normal',
						},
					},
					MuiTextField: {
						defaultProps: {
							size: 'small',
						},
						styleOverrides: {
							root: {
								margin: 0,
							},
						},
					},
					MuiSelect: {
						defaultProps: {
							size: 'small',
						},
					},
					MuiButton: {
						defaultProps: {
							disableElevation: true,
						},
					},
					MuiTypography: {
						variants: [1, 2].map((count) => {
							return {
								props: {
									'aria-rowcount': count,
								},
								style: {
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									display: '-webkit-box',
									WebkitLineClamp: count.toString(),
									WebkitBoxOrient: 'vertical',
								},
							}
						}),
					},
					MuiFormLabel: {
						styleOverrides: {
							asterisk: {
								color: customRed,
							},
						},
					},
					MuiInputLabel: {
						defaultProps: {
							shrink: true,
						},
						styleOverrides: {
							root: {
								background: isLightTheme ? '#fff' : '#121212',
								padding: '0 4px 0 4px',
								marginTop: '-6px',
								marginLeft: '-10px',
								fontSize: '1.5rem',
								// sync to form style
								position: 'absolute',
								transform: 'translate(14px, -9px) scale(0.75)',
								zIndex: 1,
								// support scroll
								overflowX: 'scroll',
								textOverflow: 'clip',
								'&::-webkit-scrollbar': {
									scrollbarWidth: 'none',
									display: 'none',
								},
							},
						},
					},
					MuiPaper: {
						defaultProps: {
							elevation: 0,
						},
						styleOverrides: {
							rounded: {
								borderRadius: '8px',
							},
						},
					},
					MuiFormHelperText: {
						styleOverrides: {
							root: {
								marginLeft: '8px',
							},
						},
					},

					// NOTEAdd standard style for Admin
					// @ts-ignore RaListToolbar does not existed
					RaListToolbar: {
						styleOverrides: {
							// NOTE: filters
							root: {
								padding: '8px 16px 4px !important',
								borderRadius: '4px',
								marginBottom: '8px',
								background: isLightTheme ? '#fff' : '#121212',
							},
						},
					},
					MuiDialogContent: {
						styleOverrides: {
							// NOTE: Save-current-query dialog
							root: {
								paddingTop: '16px !important',
							},
						},
					},
				},
			},
			t,
			{
				palette: { primary: mainPalette, secondary: mainPalette },
			},
		)
	}, [isLightTheme])

	const theme = useMemo(() => {
		return createTheme(config)
	}, [config])

	useLayoutEffect(() => {
		if (!isLightTheme) {
			return injectCss(/* css */ `
		::-webkit-calendar-picker-indicator {
			filter: invert(1);
		}
		`)
		}
	}, [isLightTheme])

	const toggleThemeType = useCallback(() => {
		const newThemeType = themeType === 'light' ? 'dark' : 'light'
		setThemeType(newThemeType)
		setCookie('themeType', newThemeType)
	}, [themeType, setThemeType])
	return { config, theme, themeType, toggleThemeType }
}

export const PreferencesContext = createContext(
	// prettier-ignore
	null as unknown as Omit<ReturnType<typeof useProviderPreference>, 'theme'>,
)

export const usePreference = () => {
	return useContext(PreferencesContext)
}
