type PaletteMode = 'light' | 'dark'

type Cookie = {
	themeType?: PaletteMode
}

export const getCookie = (cookiesStr: string = '') =>
	cookiesStr
		.split('; ')
		.filter(Boolean)
		.reduce((acc, cookieStr) => {
			const [name, value] = cookieStr.split('=')
			// @ts-ignore force assign on string type
			acc[name] = value
			return acc
		}, {} as Cookie)

export const setCookie = (key: keyof Cookie, value: string) => {
	const NEVER_EXPIRED = 'expires=Fri, 31 Dec 9999 23:59:59 GMT'
	document.cookie = `${key}=${value};path=/;${NEVER_EXPIRED}`
}
