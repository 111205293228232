import { useCallback } from 'react'
import { useBoolean } from 'ui/common/hooks/state'

import Dialog, { DialogProps } from '@mui/material/Dialog'

type Props = Omit<DialogProps, 'open' | 'onClose'> & {
	open?: boolean
	onClose?: () => void
	allowBackdropClick?: boolean
}

export const useDialog = ({
	onClose,
	open: initial,
	allowBackdropClick,
	...rest
}: Props = {}) => {
	const [open, { setTrue: openDialog, setFalse: closeDialog }] =
		useBoolean(initial)
	const close = useCallback(() => {
		closeDialog()
		if (onClose) {
			onClose()
		}
	}, [closeDialog, onClose])
	const render = (children: React.ReactNode) => {
		return (
			<Dialog
				open={open}
				onClose={(_, reason) => {
					if (!allowBackdropClick && reason === 'backdropClick') {
						return
					}

					close()
				}}
				fullWidth
				maxWidth="xs"
				{...rest}
			>
				{children}
			</Dialog>
		)
	}
	return {
		open,
		openDialog,
		closeDialog: close,
		render,
	}
}
