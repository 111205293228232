import { css } from '@emotion/react'

export const globalStyle = css`
	img[height][width] {
		width: 100%;
		height: auto;
	}
	#snack > div {
		pointer-events: all;
		gap: 4px;
	}
`
