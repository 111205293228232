import { stringify } from 'qs'
import { generatePath } from 'react-router-dom'
import { State } from 'ui/modules/routes/components/types'

import { Param } from '../common/utils/url'
import { ExtractRouteParams } from './types'

export type PageParam = { [key: string]: string | number | boolean }

export type PageParamProps = {
	pathParam?: PageParam
	param?: Param
}

export const genGetPath =
	<S extends State, P extends string = string>(path: P) =>
	(
		pathParam?: ExtractRouteParams<P>,
		param?: Param,
		state?: { [key in keyof S]: string },
	) => {
		return {
			to: {
				pathname: generatePath(path, pathParam as any),
				search: param ? `?${stringify(param)}` : '',
			},
			state,
		}
	}
