import { ApplicationProps } from 'ui/common/components/Application'

import { PaletteColorOptions } from '@mui/material/styles/createPalette'

import Form from './Form'

export const appProps: ApplicationProps = {
	rootRoutes: [Form],
	theme: () => ({
		palette: {
			primary: '#1e88e5' as PaletteColorOptions,
		},
	}),
}
