import { memo, useEffect } from 'react'
import { FallbackProps } from 'react-error-boundary'
import Center from 'ui/common/components/Center'
import ResetButton from 'ui/common/components/ResetButton'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { captureException } from '@sentry/react'

const reload = () => window.location.reload()

const ErrorFallback = ({ error }: FallbackProps) => {
	const { onLine } = window.navigator
	const title = onLine ? 'หน้าเว็บไซต์ทำงานผิดพลาด T^T' : 'ไม่มีสัญญาณเน็ต'
	const errMsg = onLine ? error?.message || '' : 'เว็บทำงานออฟไลน์'

	useEffect(() => {
		captureException(error)
	}, [])

	return (
		<Container maxWidth="md">
			<Center height={'100vh'} gap={2}>
				<Typography variant="h6" gutterBottom align="center">
					{title}
				</Typography>
				<Box display="flex" gap={1}>
					<Button variant="outlined" onClick={reload}>
						Reload
					</Button>
					<ResetButton />
				</Box>
				<Divider light />
				<Typography align="center" variant="overline">
					{errMsg}
				</Typography>
			</Center>
		</Container>
	)
}

export default memo(ErrorFallback)
