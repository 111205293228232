type Attr = { [key: string]: string | (() => void) }

export const injectScript = (url: string, { cb, ...attr }: Attr = {}) => {
	const script = document.createElement('script')
	script.src = url
	script.defer = true
	script.onload = (cb as () => {}) || null
	if (attr) {
		Object.entries(attr).forEach(([key, value]) => {
			script.setAttribute(key, value as string)
		})
	}
	document.head.appendChild(script)

	return () => {
		document.head.removeChild(script)
	}
}

export const injectCss = (css: string) => {
	const style = document.createElement('style')
	style.innerHTML = css
	document.head.appendChild(style)

	return () => {
		document.head.removeChild(style)
	}
}

export const injectLink = (url: string, { cb, ...attr }: Attr = {}) => {
	const link = document.createElement('link')
	link.onload = (cb as () => {}) || null
	if (attr) {
		Object.entries(attr).forEach(([key, value]) => {
			link.setAttribute(key, value as string)
		})
	}
	link.href = url
	document.head.appendChild(link)

	return () => {
		document.head.removeChild(link)
	}
}
