import { BrowserHistory, createBrowserHistory } from 'history'
import {
	ReactNode,
	startTransition,
	useLayoutEffect,
	useRef,
	useState,
} from 'react'
import { Navigator, Router } from 'react-router-dom'

const SuspenseRouter = ({ children }: { children: ReactNode }) => {
	const historyRef = useRef<BrowserHistory>()
	const navigatorRef = useRef<Navigator>()

	if (historyRef.current == null) {
		historyRef.current = createBrowserHistory({ window })
	}

	const history = historyRef.current
	const [state, setState] = useState({
		action: history.action,
		location: history.location,
	})

	if (navigatorRef.current == null) {
		navigatorRef.current = {
			go: (delta) => {
				startTransition(() => {
					history.go(delta)
				})
			},
			push: (to, state) => {
				startTransition(() => {
					history.push(to, state)
				})
			},
			replace: (to, state) => {
				startTransition(() => {
					history.replace(to, state)
				})
			},
			createHref: history.createHref,
		}
	}

	useLayoutEffect(() => {
		return history.listen(setState)
	}, [history])

	return (
		<Router
			location={state.location}
			navigationType={state.action}
			navigator={navigatorRef.current}
		>
			{children}
		</Router>
	)
}

export default SuspenseRouter
