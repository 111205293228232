import { useConfirmDialog } from 'ui/common/hooks/dialog/confirm'
import { logout } from 'ui/modules/auth/queries'

import Button from '@mui/material/Button'

import { clearCaches } from './utils'

const clearAll = async () => {
	await logout()
	await clearCaches()
	window.location.reload()
}

const ResetButton = () => {
	const resetDialog = useConfirmDialog({
		title: 'Reset everything!',
		content:
			'When you face to unexpected behavior. This action might be a great alternative. By doing this. The current account session and preferences will be reset.',
		confirm: clearAll,
		confirmTxt: 'Clear',
	})

	return (
		<>
			{resetDialog.dialog}
			<Button variant="outlined" onClick={resetDialog.openDialog}>
				Reset
			</Button>
		</>
	)
}

export default ResetButton
